.App {
    text-align: center;
}

  
.App-logo {
  height: 20vmin;
  pointer-events: none;
  margin-top: 0px;
  margin-bottom: 0px;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 40s linear;
  }
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
  
.App-header {
  background-color: white;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #282c34;
}

 
  
.App-link {
  color: #61dafb;
}
  
  


.article-div {

  text-align: left;
  align-items: center;
  max-width: 48rem;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 100px;
}

.article-start{


}

.heading{

    font-size: 1.4rem;
   
}


.writing{

    font-size: 1.1rem;

}





/* Footer */


.div-container {
  display: flex;
  flex-direction: row;
}


.footer-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  flex: 1;
}


.footer-second{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  padding-right: 100px;
}


.footer-second p {
  margin-right: 10px;
}

@media (min-width:600px) and (max-width:900px){

  .footer-second{
    display: flex;
    align-items: center;
    flex: 1;
    margin-left: 80px;
  }
}


.footer-list{
  list-style: none;
  display: flex;
  padding: 0;
}

.footer-list li{
  margin-right: 10px;
}


.footer-list i {

  cursor: pointer;
}


/* Slider & Darkmode */
.icon-container{
  
  text-align: left;
  margin-left: 30px;
  padding-top: 150px;
  margin-bottom: 0px;
}


.slider-button {
  background-color: #ccc;
  border-radius: 30px;
  cursor: pointer;
  position: relative;
  padding: 5px;
    

}

.slider {
  width: 20px;
  height: 1.7rem;
  border-radius: 50%;
  position: absolute;
  top: 2.5px;
  left: 2.5px;
  transition: transform 0.2s;
    
    
}


.slider-button.dark-mode {
  background-color: #e2e7b5;
}
  

  
.slider-button.dark-mode .slider {
  transform: translateX(25px);
}
 

/* Slider Controls*/
.div-container.dark-mode {
  
  color: #282c34;
}

.App-header.dark-mode {
  background-color: #282c34;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App.dark-mode{
  background-color: #282c34;

}



