
.navbar {
background-color: #fff;
padding: 10px;
display: flex;
justify-content: space-between;
align-items: center;

}
   
.navbar.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 100;
}
      
      
      
.logo {
    font-size: 24px;
    font-weight: bold;
}
        
.logo-text {
    color: #333;
}
        
.nav-menu {
    list-style: none;
    display: flex;
    align-items: center;
    margin-right: 120px;
}
        
      
.nav-item {
    margin-right: 20px;
            
}
      
      
.nav-link {
    text-decoration: none;
    color: #555;
}
      
      
.nav-link:hover {
    color: #000;
}