body {
    font-family: 'Sono', sans-serif;
  }

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    gap: 2rem;
  }

  .container-item {
    flex: 1;
    max-width: 400px;
    min-width: 300px;
    padding: 20px;
    background-color: #f2f2f2;
    border-radius: 5px;
    text-align: center;
  }

  .title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .final {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .features {
    list-style: none;
    padding: 0;
    margin: 0;
    font-family: 'SFProDisplay', sans-serif;
  }

  .features li {
    margin-bottom: 20px;
  }

  .plan-button {
    padding: 10px;
    background-color: #ff6600;
    color: #fff;
    border-radius: 5px;
    border: none;
    text-decoration: none;
  }

  .plan-button > a{
    text-decoration: none;
  }

  @media (max-width: 1250px) {
    .container {
      flex-direction: column;
      height: 100%;
    }
  }


  
