  
  .article-container {
    width: 80%;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    font-family: Arial, sans-serif;

  }

  .article-title {
    font-size: 50px;
    font-weight: bold;
    margin-bottom: 20px;
  }


  .article-entry {
    font-size: 18px;
    line-height: 1.5;
  }

